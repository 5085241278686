@import '~bootstrap/scss/bootstrap';

body {
	min-height: 200px;
	padding-bottom: 30px;
}

h1, h2, h3, h4, h5, h6 {
	margin-bottom: 0.5em;
	line-height: 1.6em;
}

.sidebar {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 100;
	padding: 48px 0 0;
	box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);

	.nav-link.active {
		color: #555;
	}

	.nav-link .sidebar_icon {
		width: 20px;
	}
}

main {
	padding-top: 48px;
}

.bb {
	border-bottom: 1px solid  rgba(0, 0, 0, 0.125);;
}

.clickable {
	cursor: pointer;
}
.draggable {
	cursor: grab;
}


#tbl_categories {
	td.indent1 {}
	td.indent2 { padding-left: 2em; }
	td.indent3 { padding-left: 4em; }
	td.indent4 { padding-left: 6em; }
	td.indent5 { padding-left: 8em; }
}

#tbl_modules, #ul_modules {
	.category .label {
		font-style: italic;
	}
	.category.indent1 .label {}
	.category.indent2 .label { padding-left: 2em; }
	.category.indent3 .label { padding-left: 4em; }
	.category.indent4 .label { padding-left: 6em; }
	.category.indent5 .label { padding-left: 8em; }

	.module .label {
		font-weight: bold;
	}
	.module.indent1 .label { padding-left: 2em; }
	.module.indent2 .label { padding-left: 4em; }
	.module.indent3 .label { padding-left: 6em; }
	.module.indent4 .label { padding-left: 8em; }
	.module.indent5 .label { padding-left: 10em; }
}


/*
 * Login page
 */
.form-signin {
	max-width: 400px;
	margin: 0 auto;
}


/*
 * Chains page
 */
.is_active_icon {
	background-color: #ccc;
	border-radius: 12px;
	display: inline-block;
	height: 24px;
	margin-right: 5px;
	width: 24px;

	&.is_active {
		background-color: green;
	}
	&.is_dead {
		background-color: red;
	}
}

#tbl_chains {
	tr.customer .label {
		font-style: italic;
	}
	tr.chain .label {
		padding-left: 2em;
		font-weight: bold;
	}
	tr.chain_link .label {
		padding-left: 4em;
	}
}

#ul_chain_links, #ul_modules {

	li.sortable-chosen .buttons {
		display: none;
	}

}

#ul_chain_links {
	min-height: 100px;
}

#modal_queues_checker {

	.spnr_ok {
		color: green;
	}
	.spnr_nok {
		color: red;
	}
	.error_container {
		margin-left: 20px;

		ul {
			margin-bottom: 1%;
		}
	}

	div.todo {
		.spnr_busy {
			display: none;
		}
		.spnr_ok {
			display: none;
		}
		.spnr_nok {
			display: none;
		}
	}
	div.busy {
		.spnr_todo {
			display: none;
		}
		.spnr_ok {
			display: none;
		}
		.spnr_nok {
			display: none;
		}
	}
	div.ok {
		.spnr_todo {
			display: none;
		}
		.spnr_busy {
			display: none;
		}
		.spnr_nok {
			display: none;
		}
	}
	div.nok {
		.spnr_todo {
			display: none;
		}
		.spnr_busy {
			display: none;
		}
		.spnr_ok {
			display: none;
		}
	}

}


/*
 * Queues page
 */
body[data-page="queues"] {

	h2 {
		display: inline-block;
	}

	.filter_container {
		width: 60%;
		float: right;
	}

}
